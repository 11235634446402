@use 'src/styles/constants' as *;

.simple-table {
  $self: &;
  box-sizing: border-box;
  font-family: $roboto;
  box-shadow: $default-box-shadow;
  border-radius: $default-border-radius;
  &__row {
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(var(--num-cols, 3), 1fr);
    align-items: center;
    column-gap: 20px;
    padding: 8px;
    position: relative;
    background-color: white;
    &:hover {
      background-color: $light-grey;
    }
    hr {
      border: 1px solid $light-grey;
      width: 100%;
    }
    &:first-of-type {
      font-weight: bold;
      border-radius: $default-border-radius;
      background-color: $primary-teal;
      padding: 18px 16px;
      box-sizing: border-box;
      box-shadow: $default-box-shadow;
      position: relative;
      z-index: 1;
      #{ $self }__cell {
        font-size: 14px;
      }
    }
    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &:not(:first-of-type) {
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 8px;
        width: calc(100% - 16px);
        height: 1px;
        background-color: $light-grey;
      }
    }
    &:last-of-type {
      border-bottom-left-radius: $default-border-radius;
      border-bottom-right-radius: $default-border-radius;
      &::after {
        content: none;
      }
    }
  }
  &__cell {
    font-size: 16px;
    &:first-of-type {
      font-weight: bold;
      margin-left: 6px;
    }
  }
}
