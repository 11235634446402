@use 'src/styles/constants' as *;

.annual-beano-page {
  max-width: $content-max-width;
  h1 {
    margin-bottom: 16px;
  }
  &__form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    & > *:first-child {
      width: 100%;
    }
  }
}
