@use 'src/styles/constants' as *;

button.button-primary {
  background-color: $primary-teal;
  color: $almost-black;
  min-width: 0;
  padding: 10px;
  &:hover {
    text-decoration: underline;
  }
}
