@use 'src/styles/constants' as *;

.licensee-search-results {
  display: flex;
  flex-direction: column;
  width: 100%;
  h2 {
    margin-bottom: 8px;
    &:last-of-type {
      margin-top: 24px;
    }
  }
  &__cta-container {
    display: flex;
    align-items: center;
    column-gap: 16px;
    row-gap: 4px;
    justify-content: space-between;
  }
  &__date-container {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }
  &__table {
    &__row {
      grid-template-columns: 1fr 1fr 1.8fr;
    }
  }
}
