@use 'src/styles/constants' as *;

.annual-beano-report-submitted {
  color: $almost-black;
  font-size: 16px;
  font-weight: normal;
  font-family: $roboto;
}

.annual-beano-print-link {
  &__content {
    display: none;
  }
}
