@use 'src/styles/constants' as *;

.icon-alert {
  border-radius: 50%;
  background-color: $error-red;
  color: white;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: help;
}
