@use 'src/styles/constants' as *;

.icon-caret {
  font-size: 0;
  height: min-content;
  line-height: normal;
  transform-origin: center center;
  transition: transform 0.4s;
  width: min-content;
}
