@use 'src/styles/constants' as *;

.weekly-beano-ui {
  max-width: $breakpoint-tablet;
  &--full-form {
    max-width: $content-max-width;
  }
  h1 {
    margin-bottom: 16px;
  }
  &__form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
    & > *:first-child {
      width: 100%;
    }
  }
}

.weekly-beano-report-submitted {
  color: $almost-black;
  font-size: 16px;
  font-weight: normal;
  font-family: $roboto;
}

.weekly-beano-submission-failure {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 12px;
  margin-top: 32px;
  &__text {
    font-size: 16px;
    font-family: $roboto;
  }
}

.weekly-beano-print-link {
  &__content {
    display: none;
  }
}
