@use 'src/styles/constants' as *;

.table-blank {
  box-shadow: $default-box-shadow;
  border-radius: $default-border-radius;
  background-color: white;
  &--transparent {
    background-color: transparent;
  }
  &__header {
    background-color: $table-teal-3;
    border-radius: $default-border-radius;
    box-shadow: $default-box-shadow;
    height: 46px;
    width: 100%;
  }
  &__contents {
    padding: 12px;
  }
}
