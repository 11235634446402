@use 'src/styles/constants' as *;

.licensee-search-page {
  h1 {
    margin-bottom: 14px;
  }
  &__contents {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  &__no-results {
    color: $almost-black;
    font-size: 16px;
    line-height: 21px;
    font-family: $roboto;
    text-align: center;
  }
}
