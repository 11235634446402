@use 'src/styles/constants' as *;
@use 'src/styles/mixins' as *;

.permit-holder-search-box {
  @include card;
  padding: 32px;
  &__input-container {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-top: 16px;
    justify-content: flex-start;
    & > *:first-child {
      min-width: 350px;
      @media screen and (max-width: $breakpoint-mobile) {
        min-width: unset;
      }
    }
  }
}
