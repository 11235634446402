@use 'src/styles/constants' as *;

.footer {
  width: 100%;
  background-color: $primary-navy;
  box-sizing: border-box;
  &__contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 72px 20px;
    row-gap: 24px;
  }
  &__contact-us {
    text-align: center;
    font-family: $roboto-slab;
    color: white;
    font-size: 18px;
    line-height: 22px;
  }
  &__divider {
    $divider-size: 5px;
    min-height: $divider-size;
    min-width: $divider-size;
    max-height: $divider-size;
    max-width: $divider-size;
    background-color: white;
    border-radius: 50%;
  }
  &__contact-method {
    text-align: center;
    font-family: $roboto;
    color: white;
    text-decoration: none;
    font-size: 16px;
    line-height: 21px;
    &#mlcg-phone-contact {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__top {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 16px;
    @media screen and (min-width: $breakpoint-tablet) {
      column-gap: 16px;
      flex-direction: row;
    }
  }
  &__bottom {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__legalese {
    color: white;
    font-family: $roboto;
    font-size: 10px;
    text-align: center;
  }
}
