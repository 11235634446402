@use 'src/styles/constants' as *;

.button-continue {
  min-width: unset;
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-family: $roboto-slab;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  span {
    display: block;
    font-family: $roboto-slab;
  }
  svg {
    transform: rotate(-90deg);
  }
  &:hover {
    span {
      text-decoration: underline;
    }
  }
}
