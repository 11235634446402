@use 'src/styles/constants' as *;

.permit-holder-search-page {
  h1 {
    margin-bottom: 14px;
  }
  &__contents {
    margin-top: 16px;
  }
  &__loading {
    display: flex;
    align-items: center;
  }
  &__error {
    margin-top: 8px;
    text-align: center;
  }
}
