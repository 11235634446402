@use 'src/styles/constants' as *;

.page {
  $default-margin-top: 32px;
  $default-page-padding: 0 8px;
  width: 100%;
  max-width: $content-max-width;
  margin: auto;
  margin-top: $default-margin-top;
  box-sizing: border-box;
  padding: $default-page-padding;
  @media screen and (min-width: $breakpoint-tablet) {
    padding: 0 16px;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    padding: $default-page-padding;
  }
  h1 {
    font-size: 24px;
    color: $almost-black;
    font-family: $roboto-slab;
  }
  &--with-breadcrumb {
    margin-top: 8px;
    h1 {
      margin-top: calc($default-margin-top - 8px);
    }
  }
}
