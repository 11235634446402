@use 'src/styles/constants' as *;

@mixin default-input {
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 4px;
  border: 1px solid $input-border-color;
  padding: 10px 8px;
  box-shadow: inset 0px 1px 3px 0px rgba(15, 24, 33, 0.2);
}

@mixin default-input-label {
  font-family: $roboto;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
}

@mixin input-field {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  row-gap: $input-flex-gap;
}

@mixin card {
  padding: $default-padding;
  box-sizing: border-box;
  border-radius: $default-border-radius;
  background-color: white;
  box-shadow: $default-box-shadow;
}

@mixin default-h2 {
  color: $almost-black;
  font-family: $roboto-slab;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}
