@use 'src/styles/constants' as *;

.submission-failure {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 12px;
  margin-top: 32px;
  &__text {
    font-size: 16px;
    font-family: $roboto;
  }
}
