@use 'src/styles/mixins' as *;

.report-submitted {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  &__contents {
    width: 100%;
    @include card;
    padding: 32px;
  }
}
