@use 'src/styles/constants' as *;

.menu-bar {
  background-color: $primary-navy;
  padding: 12px;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px 0px rgba(15, 24, 33, 0.3);
  position: relative;
  &__container {
    width: 100%;
    max-width: $content-max-width;
    margin: auto;
  }
}
