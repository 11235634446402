@use 'src/styles/constants' as *;

.breadcrumb {
  display: flex;
  flex-direction: row;
  color: rgb(15, 24, 33);
  font-family: Roboto;
  font-size: 12px;
  line-height: 16px;
  font-style: italic;
  font-weight: bold;
  padding-top: 8px;
  padding-bottom: 8px;
  letter-spacing: -0.25px;
  flex-wrap: wrap;

  a {
    color: inherit;
    text-decoration: none;
  }

  &-arrow {
    font-family: Roboto;
    font-weight: normal;
    font-style: italic;
    margin-left: 5px;
    margin-right: 5px;
  }

  &-current-page {
    font-style: italic;
    color: rgb(15, 24, 33);
    font-family: Roboto;
    font-size: 12px;
    font-style: italic;
    font-weight: normal;
    height: 16px;
    letter-spacing: -0.25px;
  }
}
