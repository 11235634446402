@use 'src/styles/constants' as *;

.input-textarea {
  font-family: $roboto;
  width: 100%;
  padding: $default-padding;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: white;
  box-shadow: inset 0px 1px 3px 0px rgba(15, 24, 33, 0.2);
  border: 1px solid $input-border-color;
  font-size: 16px;
  line-height: 19px;
  &::placeholder {
    font-style: italic;
  }
  &--error {
    border: 1px solid $error-red;
  }
}
