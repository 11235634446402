@use 'src/styles/constants' as *;

.main-nav {
  width: 100%;
  ul {
    list-style-type: none;
    padding: 0;
  }
  &__link {
    padding: 12px 18px;
    background-color: white;
    box-sizing: border-box;
    width: 100%;
    display: inline-block;
    text-decoration: none;
    margin: 6px 0;
    border-radius: $default-border-radius;
    box-shadow: 0px 1px 3px 0px rgba(15, 24, 33, 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      color: $almost-black;
      font-size: 18px;
      font-weight: bold;
      font-family: $roboto-slab;
      line-height: 22px;
    }
    &:hover {
      box-shadow: 0px 1px 3px 0px rgba(15, 24, 33, 0.5);
      h3 {
        text-decoration: underline;
      }
    }
  }
}
