@use 'src/styles/constants' as *;
@use 'src/styles/mixins' as *;

.anything-to-report {
  @include card;
  padding: 32px;
  width: 100%;
  h2 {
    color: $almost-black;
    font-family: $roboto;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 16px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
  }
  &__contact-fields {
    margin-top: 12px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
  }
  &__no-game-reason {
    margin-top: 8px;
    min-height: 80px;
  }
  &__radios {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    row-gap: 8px;
  }
  &__radio {
    padding-left: 0;
  }
}
