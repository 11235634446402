@use 'src/styles/constants' as *;

.payment-link {
  font-size: 16px;
  font-weight: bold;
  font-family: $roboto;
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  padding: 0;
}
