@import url('https://fonts.googleapis.com/css?family=Roboto:400,700,900|Roboto+Slab:400,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap');

// Typefaces
$roboto-slab: 'Roboto Slab', serif;
$roboto: 'Roboto', sans-serif;

// Colors
$app-background: #f0f3f6;
$primary-navy: #173e67;
$primary-teal: #7fcbc4;
$almost-black: #121921;
$error-red: #ff0000;
$light-grey: #e8f0f9;
$table-teal-3: #58c2b7;

// Breakpoints
$breakpoint-small: 320px;
$breakpoint-mobile: 640px;
$breakpoint-tablet: 720px;
$breakpoint-desktop: 960px;

// Commmon Styles
$default-border-radius: 6px;
$default-padding: 12px;
$default-box-shadow: 0px 1px 3px 0px rgba(15, 24, 33, 0.3);
$content-max-width: 945px;
$input-flex-gap: 4px;
$input-border-color: #aab3be;
