@use 'src/styles/constants' as *;

.error-message {
  opacity: 0.5;
  font-family: $roboto;
  font-size: 14px;
  font-style: italic;
  line-height: 1.29;
  letter-spacing: -0.26px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
